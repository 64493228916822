import moment from 'moment/moment';
import React, { useContext, useEffect, useState } from 'react';

import * as style from 'styles/components/account/seasonPass.module.scss';
import { LangContext } from 'context/LangContext';
import Ticket from 'components/account/TicketItem';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const SeasonPassItem = ({ seasonPass, axsUrl }) => {
    const { lang } = useContext(LangContext);

    const [visibleTicketGroups, setVisibleTicketGroups] = useState(false);
    const [groupedTickets, setGroupedTickets] = useState({});

    const groupTicketsByMonth = (tickets) => {
        const grouped = {};
        tickets
            .sort((a, b) => new Date(a.matchData.date) - new Date(b.matchData.date))
            .forEach(t => {
                const formattedDate = moment(t.matchData.date).format('MMMM yyyy');
                if (grouped[formattedDate]) {
                    grouped[formattedDate].push(t);
                } else {
                    grouped[formattedDate] = [];
                    grouped[formattedDate].push(t);
                }
            });

        return grouped;
    };

    useEffect(() => {
        setGroupedTickets(groupTicketsByMonth(seasonPass.Tickets));
    }, [seasonPass.Tickets]);

    return (
        <div className={`${style.seasonPass} bg`}>
            <p className={`${style.seasonPass__seasonLabel}`}><FormattedMessage id="account.seasonPass.seasonPass" /></p>

            <div className={`${style.seasonPass__content} ${style.seasonPass__contentWithButton}`}>
                <div className={`${style.seasonPass__content__data}`}>
                    <div className={`${style.seasonPass__content__data__container}`}>
                        <p>
                            <FormattedMessage id="account.seasonPass.customerNr" />
                            <span>{seasonPass.customerId}</span>
                        </p>
                        <p>
                            <FormattedMessage id="account.seasonPass.orderNr" />
                            <span>{seasonPass.orderId}</span>
                        </p>

                        {/* Todo no customer name in the API yet, maybe to remove */}
                        {/* <p className={`${style.seasonPass__content__data__container__name}`}> */}
                        {/*     {seasonPass.username} */}
                        {/* </p> */}
                    </div>
                    <div className={`${style.seasonPass__content__data__container}`}>
                        <p>
                            <FormattedMessage id="account.seasonPass.section" />
                            <span>{seasonPass.section}</span>
                        </p>
                        <p>
                            <FormattedMessage id="account.seasonPass.row" />
                            <span>{seasonPass.rowNumber}</span>
                        </p>
                        <p>
                            <FormattedMessage id="account.seasonPass.seat" />
                            <span>{seasonPass.seatNumber}</span>
                        </p>
                    </div>
                </div>

                <div className={style.seasonPass__center}>
                    <div className={style.seasonPass__rightButtons}>
                        <a href={axsUrl} target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.ticket.manage', lang)}>
                            <FormattedMessage id="account.ticket.manage" />
                        </a>

                        {/* Todo no season pass url link yet in the API, maybe to remove */}
                        {/* <a href="" target="_blank" rel="noreferrer" className="cta-white"> */}
                        {/*     <FormattedMessage id="account.seasonPass.showPass" /> */}
                        {/* </a> */}
                    </div>
                </div>
            </div>

            <div className={style.seasonPass__bottomButtons}>
                <a href={axsUrl} target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.ticket.manage', lang)}>
                    <FormattedMessage id="account.ticket.manage" />
                </a>

                {/* Todo no season pass url link yet in the API, maybe to remove */}
                {/* <a href="" target="_blank" rel="noreferrer" className="cta-white"> */}
                {/*     <FormattedMessage id="account.seasonPass.showPass" /> */}
                {/* </a> */}
            </div>

            { /* eslint-disable-next-line */}
            <div className={style.seasonPass__viewTicketsActionRow}
                 onClick={() => setVisibleTicketGroups(!visibleTicketGroups)}
            >
                {!visibleTicketGroups && <span><FormattedMessage id="account.seasonPass.viewAllTickets" /></span>}
                {visibleTicketGroups && <span><FormattedMessage id="account.seasonPass.closeAllTickets" /></span>}
                <i className={`ico-chevron ${!visibleTicketGroups && style.seasonPass__chevronRotate}`}
                   aria-hidden="true" />
            </div>

            {visibleTicketGroups && Object.keys(groupedTickets).map(key => (
                <div key={key} className={style.seasonPass__ticketGroupContainer}>
                    <span className="text-xs text-medium text-darker">{key}</span>

                    {groupedTickets[key].map((ticket) => (
                        <div key={ticket.id} className={style.seasonPass__ticketContainer}>
                            <Ticket ticket={ticket} isSeasonPass axsUrl={axsUrl} />
                        </div>
                    ))}
                </div>
            ))}

            {visibleTicketGroups && Object.keys(groupedTickets).length === 0 && (
                <div className={style.seasonPass__noTickets}>
                    <p>
                        <FormattedMessage id="account.seasonPass.noSeasonPassTickets" />
                    </p>
                </div>
            )}
        </div>
    );
};

export default SeasonPassItem;
