import React, { useContext, useEffect } from 'react';

import * as style from 'styles/components/account/seasonPassQueue.module.scss';
import { LangContext } from 'context/LangContext';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const STATUS_MAP = {
    'active': { translation: 'account.seasonPassQueue.status.active', style: style.seasonPassQueue__statusIndicatorActive },
    'inactive': { translation: 'account.seasonPassQueue.status.inactive', style: style.seasonPassQueue__statusIndicatorInactive },
};

const SeasonQueueItem = ({ seasonPassQueueData }) => {
    const { lang } = useContext(LangContext);

    const activestate = seasonPassQueueData.isActive ? "active" : "inactive";

    let queueActive = false;
    if( activestate === 'active' ) {
        queueActive = true;
    }

    useEffect(() => {

    }, [seasonPassQueueData]);

    return (
        <div className={`${style.seasonPassQueue} bg`}>
            <div className={`${style.seasonPassQueue__title}`} >
                <p className={`${style.seasonPassQueue__seasonLabel}`}>
                    <FormattedMessage id="account.seasonPassQueue.title" />
                </p>
                <div className="season-queue-status">

                    <FormattedMessage id={STATUS_MAP[activestate].translation} />
                    <span className={`${style.seasonPassQueue__statusIndicator} ${STATUS_MAP[activestate].style}`} />
                </div>
            </div>

            {seasonPassQueueData.email && queueActive &&(
                <>
                <div className={`${style.seasonPassQueue__content} ${style.seasonPassQueue__contentWithButton}`}>
                    <div className={`${style.seasonPassQueue__content__data}`}>
                        <div className={`${style.seasonPassQueue__content__data__container}`}>
                            <p>
                                <span>{seasonPassQueueData.email}</span>
                            </p>
                        </div>
                        <div className={`${style.seasonPassQueue__content__data__container}`}>
                            <p><FormattedMessage id="account.seasonPassQueue.position" />: <span>{seasonPassQueueData.position}</span></p>
                        </div>
                    </div>
                </div>

                <div className={style.seasonPassQueue__center}>
                    <div className={style.seasonPassQueue__rightButtons}>
                        <a href="https://ko.hammarbyfotboll.se" target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.ticket.manage', lang)}>
                            <FormattedMessage id="account.ticket.manage" />
                        </a>
                    </div>
                </div>
                </>
            )}
            {seasonPassQueueData.email && !queueActive && (
                <div className={style.seasonPassQueue__contentInfo}>
                    <p><FormattedMessage id="account.seasonPassQueue.spotIsInactive" /> <a href="https://ko.hammarbyfotboll.se" target="_blank" rel="noreferrer" aria-label="Manage queue"><FormattedMessage id="account.seasonPassQueue.manageQueueSpot" /></a></p>
                </div>
            )}
            {!seasonPassQueueData.email && (
                <div className={style.seasonPassQueue__contentInfo}>
                    <p><FormattedMessage id="account.seasonPassQueue.noQueueSpot" /> <a href="https://ko.hammarbyfotboll.se" target="_blank" rel="noreferrer" aria-label="Get in queue"><FormattedMessage id="account.seasonPassQueue.getInQueue" /></a></p>
                </div>
            )}

        </div>
    );
};

export default SeasonQueueItem;
